@use 'material-theme';
@use 'colors';
@use 'colors-new';
@use 'layout';
@use 'fonts';
@use 'prism-material-dark';
@use 'jsoneditor';

.cdk-overlay-container {
  z-index: 9999999999;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  font-feature-settings:
    'zero' 1,
    'cv08' 1;
  --mdc-dialog-supporting-text-font: Inter, sans-serif;
  background-color: colors-new.$new_bg_canvas;
  color: colors.$text1;
  width: 100%;
  height: 100%;
  transition: color 200ms;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @include fonts.agrandir;
  font-weight: 400;
  font-size: 48px;
  line-height: 72px;
}

h2 {
  @include fonts.agrandir;
  font-weight: 400;
  font-size: 42px;
  line-height: 52px;
}

h3 {
  @include fonts.agrandir;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

h5 {
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
}

p {
  padding: 0;
  margin: 0;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container {
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;

  &.scrollable {
    overflow-x: auto;
  }
}

a {
  text-decoration: none;
}

.link {
  color: colors.$primary1;

  &:hover {
    text-decoration: underline;
  }
}

.menu_divider {
  background: colors.$line;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  height: 1px;
}

hr {
  width: 100%;
  height: 1px;
  margin: 0 0 16px;
  background-color: colors.$line;
  border: 0;
}

/**
  Global material menu styles
 */
.cdk-overlay-pane {
  .mdc-tooltip {
    --mdc-plain-tooltip-container-color: #3f444d;
    box-shadow: colors.$elevation2;
    border-radius: 8px;

    .mdc-tooltip__surface {
      padding: 16px;
      max-width: 385px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border-radius: 8px;
    }
  }

  .mat-mdc-menu-panel {
    background: colors.$bg1;
    box-shadow: colors.$elevation2;
    border-radius: 12px;

    &.mat-mdc-menu-panel {
      min-width: 184px;
    }

    .mat-mdc-menu-content {
      padding: 4px 0;

      .mat-mdc-menu-item {
        --mdc-typography-body1-font-size: 14px;
        --mdc-typography-body1-line-height: 20px;
        --mdc-typography-body1-font-weight: 400;

        height: 36px;
        min-height: 0;
        display: flex;
        align-items: center;
        color: colors.$text1;
        box-sizing: border-box;
        width: 100%;
        background: transparent;
        border: none;
        margin: 0;
        font-size: var(--mdc-typography-body1-font-size);

        .mat-icon {
          margin-right: 8px;
        }

        // Needed because angular makes icons appear on left
        &.with_icon_on_right {
          flex-direction: row-reverse;

          .mat-icon {
            margin-right: 0;
          }
        }

        &:hover {
          background: colors.$hover1;
        }
      }
    }
  }
}

/** Dialogs */
.dialog_form_field {
  width: 100%;
}

.cdk-overlay-pane {
  .mat-mdc-dialog-content {
    max-height: 92vh;
  }

  .mat-mdc-dialog-container {
    --mat-dialog-content-padding: 48px;

    .mat-mdc-dialog-surface {
      background-color: colors.$bg3;
      border-radius: 12px;
      box-shadow: colors.$elevation3;

      .mdc-dialog__content {
        letter-spacing: 0;
      }

      .dialog_normal_text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        color: colors.$text2;

        .bold {
          font-weight: 700;
          color: colors.$text1;
        }
      }

      .dialog_buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-gap: 16px;
        margin-top: 36px;

        > div {
          button {
            width: 100%;
          }
        }

        button {
          font-size: 16px;
          font-weight: 600;
        }

        .delete_button_wrapper {
          grid-column: 1 / span 2;
        }
      }

      .dialog_error {
        color: colors.$fail1;
        font-size: 14px;
        line-height: 16px;
        margin-top: 15px;
      }
    }
  }
}

/** Forms */
.dark,
.light {
  // For hints
  --mdc-dialog-supporting-text-size: 12px;

  form {
    .mdc-text-field--outlined {
      --mdc-outlined-text-field-container-shape: 12px;
    }
  }

  /** Select box **/
  .mat-mdc-select {
    .mat-mdc-select-placeholder {
      color: colors.$text3;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .mat-mdc-select-value-text {
      font-size: 14px;
      line-height: 20px;
      color: colors.$text1;
    }
  }

  .cdk-overlay-pane {
    .mdc-menu-surface.mat-mdc-select-panel {
      border-radius: 8px;
      margin: 0;
      --mdc-theme-surface: var(--bg1);

      &.mat-mdc-elevation-specific.mat-elevation-z8,
      &.mat-elevation-z8 {
        box-shadow: colors.$elevation2;
      }

      .mat-mdc-option {
        min-height: 36px;

        &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
            .mdc-list-item--disabled
          ) {
          background-color: transparent;
        }

        &:hover:not(.mdc-list-item--disabled),
        &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
            .mdc-list-item--disabled
          ):hover {
          background-color: colors.$hover1;
        }

        .mdc-list-item__primary-text {
          --mdc-theme-text-primary-on-background: var(--text1);
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    display: none;
  }

  .mat-mdc-form-field-hint-wrapper {
    position: relative;

    .mat-mdc-form-field-hint {
      margin-top: 8px;
    }
  }

  .mat-form-field-appearance-outline {
    .mat-mdc-text-field-wrapper {
      height: 48px;

      &.mdc-text-field--outlined {
        .mat-mdc-form-field-infix {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    &.alt {
      &.valid {
        .mdc-text-field--outlined {
          --outline-background-color: var(--new_bg2);
        }
      }

      .mdc-text-field--outlined {
        --outline-background-color: var(--new_bg2);

        &.mdc-text-field--focused {
          --outline-background-color: var(--new_bg2);
        }
      }
    }

    &.textarea_form_field {
      .mat-mdc-text-field-wrapper {
        height: auto;
      }

      .mdc-text-field--outlined {
        padding-left: 0;
        padding-right: 0;
      }

      .mdc-text-field--outlined
        .mdc-notched-outline
        .mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: 22px;
      }

      textarea {
        padding: 0 14px;
        margin: 14px 3px 14px 0;
        font-size: 14px;
        line-height: 20px;
        box-sizing: content-box;
      }

      .text_area_floating_action_button {
        border-radius: 16px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 10;

        .button_content {
          display: flex;
          align-items: center;
          justify-content: center;

          mat-icon {
            font-size: 16px;
            height: 16px;
          }
        }
      }
    }

    .mat-mdc-form-field-flex {
      height: 100%;
    }

    .mdc-text-field--outlined {
      --mdc-theme-error: var(--fail1);
      --mdc-shape-small: 12px;
      --outline-border-color: var(--line);
      --outline-background-color: var(--bg1);
      --outline-text-color: var(--text1);

      // If the form field has a matPrefix, the label needs a margin-left of 14 so it's in the right position
      &:has(.mat-mdc-form-field-icon-prefix) {
        .mdc-notched-outline {
          .mdc-floating-label {
            margin-left: 26px;
          }

          .mdc-floating-label--float-above {
            margin-left: 0;
          }
        }
      }

      .mat-mdc-form-field-infix {
        min-height: auto;
        font-size: 14px;
        height: 100%;
        display: flex;
        align-items: center;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px colors.$bg2 inset !important;
      }

      input:-webkit-autofill {
        -webkit-text-fill-color: colors.$text3 !important;
      }

      .mat-mdc-form-field-icon-suffix,
      .mat-mdc-form-field-infix,
      .mat-mdc-form-field-icon-prefix {
        z-index: 10;
      }

      .mat-mdc-form-field-icon-suffix {
        padding: 0 16px 0 8px;
        font-size: 12px;
        color: colors.$text3;
        font-weight: 600;
      }

      .mat-mdc-form-field-icon-prefix {
        padding: 0 8px 0 16px;
      }

      .mat-mdc-form-field-icon-suffix,
      .mat-mdc-form-field-icon-prefix {
        > .mat-icon {
          padding: 0;
          font-size: 0;
          width: 16px;
          height: 16px;
          line-height: 0;
        }
      }

      &.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
        color: var(--outline-text-color);
      }

      .mdc-notched-outline {
        .mdc-floating-label {
          top: 48%;
          font-size: 14px;
          color: var(--outline-text-color);
        }

        .mdc-floating-label--float-above {
          top: 28px;
        }

        // Default
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var(--outline-border-color);
          background-color: var(--outline-background-color);
        }

        &:not(.mdc-text-field--focused):hover {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            border-color: var(--outline-border-color);
          }
        }
      }

      // Hover
      &:not(.mdc-text-field--focused):not(.mdc-text-field--disabled):hover {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var(--outline-border-color);
        }

        --outline-border-color: $text2;
      }

      // Focused
      &.mdc-text-field--focused {
        --outline-border-color: var(--primary1);
        --outline-background-color: var(--bg1);
        --outline-text-color: var(--text1);
      }

      // Invalid
      &.mdc-text-field--invalid,
      &.mdc-text-field--invalid:not(.mdc-text-field--focused):hover {
        --outline-border-color: var(--fail1);
        --outline-background-color: var(--fail2);
        --outline-text-color: var(--text1);
      }
    }

    .suffix_required_text {
      display: block;
      color: var(--outline-text-color);
    }

    .suffix_check_icon {
      display: none;
      color: colors.$success1;
    }

    &.valid {
      // Filled
      .mdc-text-field--outlined {
        --outline-border-color: var(--line);
        --outline-background-color: var(--bg1);
        --outline-text-color: var(--text1);
      }

      .suffix_required_text {
        display: none;
      }

      .suffix_check_icon {
        display: block;
      }
    }

    &.disabled {
      opacity: 0.7;
    }
  }
}

.dark,
.light {
  .mat-mdc-snack-bar-container {
    --mat-mdc-snack-bar-button-color: var(--blue-base);
    --mdc-snackbar-container-color: var(--bg1);
    --mdc-snackbar-supporting-text-color: var(--text1);
    --mdc-snackbar-supporting-text-size: 14px;
    --mdc-snackbar-supporting-text-line-height: 20px;
    margin: 16px;

    .mdc-snackbar__label {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.success {
      .mdc-snackbar__label {
        &::before {
          content: '';
          background-image: url('assets/images/check_icon_green.svg');
          background-size: 24px 24px;
          background-position: center;
          display: inline-block;
          vertical-align: middle;
          height: 20px;
          width: 24px;
          margin-right: 8px;
        }
      }
    }

    &.warning {
      .mdc-snackbar__label {
        &::before {
          content: '';
          background-image: url('assets/images/warning_icon_red.svg');
          background-size: 24px 24px;
          background-position: center;
          display: inline-block;
          vertical-align: middle;
          height: 20px;
          width: 24px;
          margin-right: 8px;
        }
      }
    }
  }
}

.mat-mdc-snack-bar-action {
  .mdc-button__label {
    color: var(--text3);
  }
}

/** Buttons */

.dark,
.light {
  .mat-mdc-unelevated-button.mat-mdc-button-base {
    height: var(--mdc-filled-button-container-height, 36px) !important;
  }

  .mat-mdc-outlined-button.mat-mdc-button-base {
    height: var(--mdc-outlined-button-container-height, 36px) !important;
  }

  .mat-mdc-button-base {
    letter-spacing: unset;
  }
}

.medium_button,
.m_button {
  white-space: nowrap;
  --mdc-filled-button-container-height: 28px !important;
  --mdc-filled-button-container-shape: 8px !important;
  --mdc-outlined-button-container-height: 28px !important;
  --mdc-outlined-button-container-shape: 8px !important;
  --mdc-typography-button-font-weight: 600 !important;
  --mdc-typography-button-font-size: 12px !important;
  font-weight: 600;
  font-size: 12px;

  &.mdc-button {
    padding: 0 16px;
  }
}

.large_button,
.l_button {
  &.mdc-button {
    white-space: nowrap;
    --mdc-filled-button-container-height: 40px !important;
    --mdc-filled-button-container-shape: 10px !important;
    --mdc-outlined-button-container-height: 40px !important;
    --mdc-outlined-button-container-shape: 10px !important;
    --mdc-typography-button-font-weight: 600 !important;
    --mdc-typography-button-font-size: 14px !important;
    font-weight: 600;
    font-size: 14px;

    padding: 0 24px;

    width: var(--mdc-button-width, auto);
    height: var(--mdc-button-height, auto);
  }
}

.backend_button {
  --mdc-button-width: 160px;
  --mdc-button-height: 40px;
}

.extra_large_button,
.xl_button {
  white-space: nowrap;
  --mdc-filled-button-container-height: 48px !important;
  --mdc-filled-button-container-shape: 12px !important;
  --mdc-outlined-button-container-height: 48px !important;
  --mdc-outlined-button-container-shape: 12px !important;
  --mdc-typography-button-font-weight: 600 !important;
  --mdc-typography-button-font-size: 16px !important;
  font-weight: 600;
  font-size: 16px;

  &.mdc-button {
    padding: 0 32px;
  }
}

.large_icon_button {
  height: 36px;
  width: 36px;
  padding: 0;
  min-width: auto;
  --mdc-filled-button-container-shape: 10px;
}

.icon_button {
  > .mat-icon {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .mat-mdc-button-touch-target {
    height: auto;
  }
}

.primary_icon_button {
  @extend .primary_button;

  &.mat-mdc-unelevated-button {
    @extend .large_icon_button;
    @extend .icon_button;
  }
}

.secondary_icon_button {
  @extend .secondary_button;

  &.mat-mdc-unelevated-button {
    @extend .large_icon_button;
    @extend .icon_button;
  }
}

.primary_button {
  &.mat-mdc-unelevated-button {
    &:not(:disabled) {
      /** Flat buttons **/
      --mdc-filled-button-container-color: var(--primary1);
      --mdc-filled-button-label-text-color: var(--text1_inverse);

      &:hover {
        --mdc-filled-button-container-color: var(--primary3);
      }
    }
  }
}

.secondary_button {
  &.mat-mdc-unelevated-button {
    &:not(:disabled) {
      /** Flat buttons **/
      --mdc-filled-button-container-color: var(--primary2);
      --mdc-filled-button-label-text-color: var(--primary1);

      &:hover {
        --mdc-filled-button-container-color: var(--primary4);
      }
    }
  }
}

.outline_button {
  &.mat-mdc-outlined-button {
    &.mat-mdc-button-base {
      height: auto;
    }

    &:not(:disabled) {
      /** Outlined buttons **/
      --mdc-outlined-button-label-text-color: var(--primary1);
      --mdc-outlined-button-outline-color: var(--primary1);
      --mat-mdc-button-persistent-ripple-color: var(--primary1);
    }
  }
}

.warning_button {
  &.mat-mdc-unelevated-button {
    &:not(:disabled) {
      /** Flat buttons **/
      --mdc-filled-button-container-color: var(--fail1);
      --mdc-filled-button-label-text-color: var(--text1_inverse);

      &:hover {
        --mdc-filled-button-container-color: var(--fail1);
        opacity: 0.8;
      }
    }
  }

  &.mat-mdc-outlined-button {
    &:not(:disabled) {
      /** Outlined buttons **/
      --mdc-outlined-button-label-text-color: var(--fail1);
      --mdc-outlined-button-outline-color: var(--fail1);
      --mat-mdc-button-persistent-ripple-color: var(--fail1);
      background-color: var(--fail2);
    }
  }

  &.with_icon_on_right {
    flex-direction: row-reverse;

    .mat-icon {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}

.success_button {
  &.mat-mdc-unelevated-button {
    &:not(:disabled) {
      /** Flat buttons **/
      --mdc-filled-button-container-color: var(--fail1);
      --mdc-filled-button-label-text-color: var(--text1_inverse);

      &:hover {
        --mdc-filled-button-container-color: var(--success1);
        opacity: 0.8;
      }
    }
  }

  &.mat-mdc-outlined-button {
    &:not(:disabled) {
      /** Outlined buttons **/
      --mdc-outlined-button-label-text-color: var(--success1);
      --mdc-outlined-button-outline-color: var(--success1);
      --mat-mdc-button-persistent-ripple-color: var(--success1);
      background-color: var(--success2);
    }
  }

  &.with_icon_on_right {
    flex-direction: row-reverse;

    .mat-icon {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}

// Checkbox

.mat-mdc-checkbox {
  .mdc-checkbox {
    --mdc-checkbox-selected-checkmark-color: var(--text1_inverse);
    --mdc-checkbox-selected-icon-color: var(--primary1);
    --mdc-checkbox-selected-pressed-icon-color: var(--primary1);
    --mdc-checkbox-selected-focus-icon-color: var(--primary1);
    --mdc-checkbox-selected-hover-icon-color: var(--primary1);
    --mdc-checkbox-unselected-hover-icon-color: currentColor;
    --mdc-checkbox-unselected-focus-icon-color: currentColor;
    --mdc-checkbox-unselected-icon-color: currentColor;
    --mdc-checkbox-unselected-pressed-icon-color: colorColor;

    .mat-mdc-checkbox-touch-target {
      width: 40px;
      height: 40px;
    }

    .mdc-checkbox__checkmark {
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .mdc-checkbox__ripple {
      background: transparent !important;
    }
  }

  .mdc-form-field {
    color: currentColor !important;

    label {
      padding-left: 0 !important;
      font-weight: 600;
    }
  }
}

.page_title {
  margin-top: 36px;
  margin-bottom: 32px;
}

.page_subtitle {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: colors.$text2;
}

.dark,
.light {
  .mdc-tab {
    --mdc-typography-button-letter-spacing: 0;
  }

  .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      border-bottom: 1px solid colors.$line;
      margin-bottom: 48px;
    }

    .mat-mdc-tab:not(.mat-mdc-tab-disabled) {
      padding-left: 0;
      padding-right: 0;
      min-width: auto;

      &:not(:last-child) {
        margin-right: 32px;
      }

      &:hover {
        .mdc-tab__ripple::before {
          background-color: transparent;
        }
      }

      .mdc-tab-indicator__content--underline {
        border-color: colors.$primary1;
        border-top-width: 4px;
        border-radius: 4px 4px 0 0;
      }

      .mdc-tab__text-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: colors.$text2;
        letter-spacing: normal; // Angular 16 default is 1.25
      }

      &.mdc-tab--active {
        .mdc-tab__content {
          .mdc-tab__text-label {
            color: colors.$text1;
          }
        }
      }
    }
  }
}

/** Tentacles */

.description_and_input_container {
  display: flex;
  align-items: center;

  .left {
    margin-right: 32px;
    max-width: 384px;
    width: 100%;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: colors.$text1;
      margin-bottom: 4px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: colors.$text2;
    }
  }

  .right {
    max-width: 384px;
    width: 100%;
  }
}

.public_outline {
  border: 1px solid colors.$primary1;
  border-radius: 4px;
  padding: 1px 4px;
  color: colors.$primary1;
  background-color: colors.$bg3;
  font-weight: 500;
}

.fake_input_wrapper {
  .fake_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border: 1px solid colors.$line;
    border-radius: 12px;
    width: 100%;
    height: 48px;

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: colors.$text1;
    }

    .icon_action,
    .text_action {
      transition:
        opacity 300ms,
        filter 300ms;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        filter: brightness(0.5);
      }
    }

    .icon_action {
      width: 16px;
      height: 16px;
      line-height: 0;
      color: colors.$primary1;
    }

    .icon_action_disabled {
      width: 16px;
      height: 16px;
      line-height: 0;
      color: colors.$text3;
    }

    .text_action {
    }
  }

  .hint {
    margin-top: 4px;
    margin-left: 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: colors.$text2;
  }
}

.buttons {
  > button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.common_table {
  &.grid_2 .row {
    grid-template-columns: repeat(2, 1fr);
  }

  &.grid_3 .row {
    grid-template-columns: repeat(3, 1fr);
  }

  &.grid_4 .row {
    grid-template-columns: repeat(4, 1fr);
  }

  &.grid_5 .row {
    grid-template-columns: repeat(5, 1fr);
  }

  &.grid_6 .row {
    grid-template-columns: repeat(6, 1fr);
  }

  &.grid_7 .row {
    grid-template-columns: repeat(7, 1fr);
  }

  &.grid_8 .row {
    grid-template-columns: repeat(8, 1fr);
  }

  .row {
    height: 40px;
    display: grid;
    align-items: center;
    column-gap: 24px;

    &.hidden_row {
      color: colors.$text3;
    }

    .column {
      display: flex;
      align-items: center;
      font-family: 'Source Code Pro', monospace;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      &.icon_column {
        display: inline-flex;
        min-width: 0;

        .reveal_icon {
          padding: 0;
          width: 16px;
          height: 16px;
          line-height: 0;
          margin-right: 8px;
          flex-shrink: 0;
        }

        .modified_dot {
          width: 6px;
          height: 6px;
          border-radius: 6px;
          display: inline-block;
          background: colors.$doc2;
          margin-left: 4px;
        }
      }

      &.action_column {
        justify-content: flex-end;

        .action_icon {
          color: colors.$primary1;
          cursor: pointer;
          transition: opacity 300ms;
        }

        &:hover {
          .action_icon {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .header_row {
    border-bottom: 1px solid colors.$line;

    .column {
      font-family: 'Inter', sans-serif;
      color: colors.$primary1;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.squid_mat_table {
  &.mat-mdc-table {
    background: colors.$bg2;
  }

  table-layout: fixed;
  width: 100%;
  border-radius: 20px;
  padding: 24px;

  tr {
    height: 40px;
  }

  th {
    &.mdc-data-table__header-cell:first-of-type {
      padding-left: 0;
    }

    border-bottom: 1px solid colors.$line;
    font-family: 'Inter', sans-serif;
    color: colors.$primary1;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  td {
    &.mat-mdc-cell {
      border-bottom: none;
    }

    &.mdc-data-table__cell:first-of-type {
      padding: 0;
    }

    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

.app_metadata_table {
  background: colors.$bg2;
  border-radius: 20px;
  padding: 24px;
  @extend .common_table;
}

.mat-icon.menu_icon {
  width: 16px;
  height: 16px;
  line-height: 0;
  margin-right: 8px;
}

.red_menu_item {
  .mat-icon,
  .mat-mdc-menu-item-text {
    color: colors.$fail1 !important;
  }
}

.text2_menu_item {
  .mat-icon,
  .mat-mdc-menu-item-text {
    color: colors.$text2 !important;
  }
}

.zero_state_image {
  .primary {
    fill: colors.$primary1;
  }
}

.danger_zone {
  .danger_zone_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
    margin-bottom: 24px;
    border-bottom: 1px solid colors.$line;
    max-width: 800px;
  }
}

.integration_icon {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 44px;
  height: 44px;
}

.auth0_integration_icon {
  background-image: url('assets/images/auth0_integration_icon.svg');
}

.functions_doc_wrapper {
  background: colors.$primary2;
  border: 1px solid colors.$primary1;
  padding: 48px;
  max-width: 792px;
  width: 100%;
  border-radius: 20px;
}

.documentation_modal,
.documentation_modal_borderless {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background-color: colors.$bg3;

    &.with_border {
      border: 1px solid colors.$primary1;
    }
  }
}

.documentation_modal {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border: 1px solid colors.$primary1;
  }
}

.documentation {
  markdown {
    & > * {
      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  blockquote {
    background: colors.$primary2;
    margin-top: 32px;
    margin-bottom: 40px;
    padding: 24px;
    border-radius: 16px;
    position: relative;

    &::before {
      content: '';
      width: 8px;
      height: 100%;
      background: colors.$primary1;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 16px 0 0 16px;
    }

    p {
      color: colors.$text1;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  h1 {
    font-family: Degular, sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    margin-top: 32px;
    margin-bottom: 24px;
    color: colors.$text1;
  }

  h2 {
    font-family: Degular, sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    margin-top: 32px;
    margin-bottom: 8px;
  }

  h3 {
    font-family: Degular, sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    color: colors.$text2;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  h4 {
    font-family: Degular, sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: colors.$text1;
    margin-top: 32px;
    margin-bottom: 8px;
  }

  h5 {
    font-family: Degular, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: colors.$text1;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: colors.$text1;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  ul {
    margin-top: 8px;
    margin-bottom: 16px;

    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: colors.$text1;
      margin-left: 24px;
    }
  }

  ol {
    margin-top: 8px;
    margin-bottom: 16px;

    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: colors.$text1;
      margin-left: 32px;
    }
  }

  code:not([class*='language-']) {
    background: colors.$primary2;
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
    color: colors.$primary1;
    padding: 0 8px;
  }

  strong {
    font-weight: 700;
  }

  pre[class*='language-'] {
    position: relative;
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 24px;
  }

  .page_container {
    @media (max-height: 1000px) {
      align-items: flex-start;
    }
  }

  a {
    text-decoration: underline;
    font-weight: bold;
    color: colors.$primary1;
  }
}

.tooltip_above {
  position: relative;
  top: -2px;

  .mdc-tooltip__surface {
    overflow: inherit;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      bottom: -8px;
      background: #3f444d;
      border-radius: 4px;
      width: 20px;
      height: 20px;
    }
  }
}

// Style-Only "Components"
.badge {
  background: colors.$doc2bg;
  color: colors.$doc2;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  padding: 1px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  white-space: nowrap;
}

.outline_badge {
  font-family: 'Source Code Pro', monospace !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 10px !important;
  padding: 4px 8px 4px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;
}

.squid_select {
  position: relative;

  // Select
  &.mat-mdc-select {
    .mat-mdc-select-trigger {
      padding-right: 22px;

      &::after {
        position: absolute;
        right: 0;
        content: '';
        background-image: url('/assets/images/chevron_down_blue.svg');
        background-size: 100% 100%;
        background-position: center;
        display: inline-block;
        background-repeat: no-repeat;
        height: 7px;
        width: 12px;
      }
    }

    .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  }

  // Panel
  &.mdc-menu-surface.mat-mdc-select-panel {
    width: unset;
    min-width: 280px;
    position: absolute;
    padding: 8px;
    background-color: colors.$bg3;
  }

  .mat-mdc-option {
    border-radius: 4px;

    .mdc-list-item__primary-text {
      white-space: nowrap !important;
      padding-right: 32px;
    }

    &.mdc-list-item--selected {
      .mdc-list-item__primary-text {
        color: inherit;

        &::after {
          position: absolute;
          right: 16px;
          content: '';
          background-image: url('/assets/images/check_icon_blue.svg');
          background-size: 20px 20px;
          background-position: center;
          display: inline-block;
          vertical-align: middle;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.gray_select {
  position: relative;

  // Select
  &.mat-mdc-select {
    .mat-mdc-select-trigger {
      padding-right: 22px;

      &::after {
        position: absolute;
        right: 0;
        content: '';
        background-image: url('/assets/icons/select_arrow_down.svg');
        background-size: 100% 100%;
        background-position: center;
        display: inline-block;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
      }
    }

    .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  }

  // Panel
  &.mdc-menu-surface.mat-mdc-select-panel {
    width: unset;
    min-width: 280px;
    position: absolute;
    padding: 8px;
    background-color: colors.$bg3;
  }

  .mat-mdc-option {
    border-radius: 4px;

    .mdc-list-item__primary-text {
      white-space: nowrap !important;
      padding-right: 32px;
    }

    &.mdc-list-item--selected {
      .mdc-list-item__primary-text {
        color: inherit;

        &::after {
          position: absolute;
          right: 16px;
          content: '';
          background-image: url('/assets/images/check_icon_blue.svg');
          background-size: 20px 20px;
          background-position: center;
          display: inline-block;
          vertical-align: middle;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

// Reusable progress bar styles.
.mdc-linear-progress__buffer,
.mdc-linear-progress__buffer-bar {
  background-color: colors.$bg2 !important;
}

// Progress bar with an alternative (magenta) color.
.progress_bar_doc2 {
  .mdc-linear-progress__bar-inner {
    border-color: colors.$doc2 !important;
  }
}

.inline_block {
  display: inline-block;
}

.dialog-panel-overflow {
  .mat-mdc-dialog-surface {
    overflow-y: unset;
  }
}

.dialog-backdrop-dark {
  background-color: rgba(0, 0, 0, 0.7);
}

.mdc-dialog {
  .mat-mdc-dialog-inner-container {
    .mat-mdc-dialog-title {
      @include fonts.agrandir;
      font-weight: 600;
      font-size: 32px;
      line-height: 45px;
      letter-spacing: -0.065px;
      color: colors.$text1;
      padding: 9px 24px;
    }
  }
}

.mat-mdc-tab-body {
  width: 100%;
}

.pre_line {
  white-space: pre-line;
}

.maximized_dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  max-width: 100vw !important;
  padding: 5px !important;;
  height: 100vh !important;
  max-height: 100vh !important;
  z-index: 1000;
  overflow: auto;
}

