@use 'colors';

$jse-white: colors.$text1 !default;
$jse-grey: #999999 !default;
$jse-light-bg: #ebebeb !default;
$jse-blue: #3883fa !default;
$jse-content-color: colors.$text1 !default;
$jse-secondary-content-color: colors.$text2 !default;

$jse-string: colors.$text1 !default;
$jse-number: colors.$text1 !default;
$jse-boolean: colors.$text1 !default;
$jse-null: colors.$text1 !default;
$jse-color-value: $jse-content-color !default;
$jse-invalid: $jse-content-color !default;
$jse-readonly: #808080 !default;
$jse-empty: #d3d3d3 !default;
$jse-preview: #f5f5f5 !default;
$jse-busy: #ffffab !default;
$jse-busy-border-color: #ffee00 !default;

$jse-error: #ee2e2e70 !default;
$jse-separator: #e5e5e5 !default;
$jse-highlight-bg: #ffee00 !default;
$jse-highlight-border-color: #ffc700 !default;

$jse-popover-bg: #4c4c4c !default;
$jse-bar-bg: $jse-light-bg !default;
$jse-bar-border: $jse-empty !default;

$jse-menu-color: $jse-empty !default;

$jse-contextmenu-color: #4d4d4d !default;

$jse-box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.3) !default;
$jse-box-shadow-sm: 0 0 5px rgba(0, 0, 0, 0.4) !default;
$jse-box-shadow-inner: inset 0 0 10px rgba(128, 128, 128, 0.5) !default;

$jse-date: colors.$text1 !default;
$jse-font: Inter, sans-serif !default;
$jse-font-mono: Roboto Mono, monospace !default;
$jse-font-size: 14px !default;

$jse-icons: "./img/jsoneditor-icons.svg" !default;
$jse-icons-url: url($jse-icons) !default;
