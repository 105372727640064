:root {
  --mdc-dialog-supporting-text-color: var(--text1);
}

:root,
.light {
  color: var(--text1);

  --primary1: #188fff;
  --primary2: rgba(24, 143, 255, 0.1);
  --primary3: #0d59d9;
  --primary4: #c4ccd9;
  --primary5: #15212f;

  --bg_canvas: #fff202;
  --bg1: #ffffff;
  --bg2: #f8f9fc;
  --bg3: #f1f3f9;

  --disabled: rgba(0, 0, 0, 0.15);
  --line: #e1e6ef;

  --text0: #000000;
  --text1: rgba(0, 0, 0, 0.87);
  --text2: rgba(0, 0, 0, 0.6);
  --text3: rgba(0, 0, 0, 0.38);

  --text1_inverse: rgba(255, 255, 255, 0.87);

  --hover1: rgba(0, 0, 0, 0.15);
  --pressed1: rgba(0, 0, 0, 0.3);
  --selected1: rgba(0, 0, 0, 0.05);

  --fail1: #eb0f29;
  --fail2: rgba(235, 15, 41, 0.1);
  --success1: #018638;
  --success2: rgba(1, 134, 56, 0.1);

  --elevation1: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
  --elevation2: 0px -1px 2px rgba(0, 0, 0, 0.08),
    0px 4px 8px rgba(0, 0, 0, 0.12);
  --elevation3: 0px 0px 16px rgba(0, 0, 0, 0.05),
  0px 28px 24px -16px rgba(0, 0, 0, 0.32);

  --doc1: #4f60ff;
  --doc1bg: rgba(79, 96, 255, 0.1);
  --doc2: #dd1d87;
  --doc2bg: rgba(221, 29, 135, 0.1);
  --doc3: #1d9eba;
  --doc3bg: rgba(29, 158, 186, 0.1);
  --doc4: #08a84a;
  --doc4bg: rgba(8, 168, 74, 0.1);
  --doc5: #a98e2f;
  --doc5bg: rgba(169, 142, 47, 0.1);
  --doc6: #d65746;
  --doc6bg: rgba(214, 87, 70, 0.1);
  --doc7: #35a388;
  --doc7bg: rgba(53, 163, 136, 0.1);
  --doc8: #66769e;
  --doc8bg: rgba(102, 118, 158, 0.1);
}

.dark {
  color: var(--text1);

  --primary1: #188fff;
  --primary2: rgba(24, 143, 255, 0.1);
  --primary3: #3ba0ff;
  --primary4: #3d4a5a;
  --primary5: #15212f;

  --bg_canvas: #fff202;
  --bg1: #1b1f27;
  --bg2: #0a0d14;
  --bg3: #23272f;

  --disabled: rgba(255, 255, 255, 0.15);
  --line: #3f444d;

  --text1: rgba(255, 255, 255, 0.87);
  --text2: rgba(255, 255, 255, 0.6);
  --text3: rgba(255, 255, 255, 0.38);

  --text1_inverse: rgba(0, 0, 0, 0.87);

  --hover1: rgba(255, 255, 255, 0.15);
  --pressed1: rgba(255, 255, 255, 0.3);
  --selected1: rgba(255, 255, 255, 0.05);

  --fail1: #ff333a;
  --fail2: rgba(255, 51, 58, 0.1);
  --success1: #00b44b;
  --success2: rgba(0, 180, 75, 0.1);

  --elevation1: 0px 0px 2px rgba(0, 0, 0, 0.68), 0px 2px 4px rgba(0, 0, 0, 0.68);
  --elevation2: 0px -1px 2px rgba(0, 0, 0, 0.36),
    0px 4px 8px rgba(0, 0, 0, 0.56);
  --elevation3: 0px 0px 16px rgba(0, 0, 0, 0.56),
  0px 28px 24px -16px rgba(0, 0, 0, 0.48);

  --doc1: #9483ff;
  --doc1bg: rgba(148, 131, 255, 0.1);
  --doc2: #f4349e;
  --doc2bg: rgba(244, 52, 158, 0.1);
  --doc3: #25cff4;
  --doc3bg: rgba(37, 207, 244, 0.1);
  --doc4: #44e871;
  --doc4bg: rgba(68, 232, 113, 0.1);
  --doc5: #f1ce52;
  --doc5bg: rgba(241, 206, 82, 0.1);
  --doc6: #f96855;
  --doc6bg: rgba(249, 104, 85, 0.1);
  --doc7: #65dec1;
  --doc7bg: rgba(101, 222, 193, 0.1);
  --doc8: #7a8fc2;
  --doc8bg: rgba(122, 143, 194, 0.1);
}

$primary1: var(--primary1);
$primary2: var(--primary2);
$primary3: var(--primary3);
$primary4: var(--primary4);
$primary5: var(--primary5);

$bg_canvas: var(--bg_canvas);
$bg1: var(--bg1);
$bg2: var(--bg2);
$bg3: var(--bg3);
$disabled: var(--disabled);
$line: var(--line);

$text1: var(--text1);
$text2: var(--text2);
$text3: var(--text3);
$text1_inverse: var(--text1_inverse);

$hover1: var(--hover1);
$pressed1: var(--pressed1);
$selected1: var(--selected1);

$fail1: var(--fail1);
$fail2: var(--fail2);
$success1: var(--success1);
$success2: var(--success2);

$doc1: var(--doc1);
$doc1bg: var(--doc1bg);
$doc2: var(--doc2);
$doc2bg: var(--doc2bg);
$doc3: var(--doc3);
$doc3bg: var(--doc3bg);
$doc4: var(--doc4);
$doc4bg: var(--doc4bg);
$doc5: var(--doc5);
$doc5bg: var(--doc5bg);
$doc6: var(--doc6);
$doc6bg: var(--doc6bg);
$doc7: var(--doc7);
$doc7bg: var(--doc7bg);
$doc8: var(--doc8);
$doc8bg: var(--doc8bg);

$elevation1: var(--elevation1);
$elevation2: var(--elevation2);
$elevation3: var(--elevation3);
$elevation-navigation: var(--elevation-navigation);
