// TODO Add doc* colors.

:root {
  --grey-base: #6e7780;
  --grey-000: #ffffff;
  --grey-100: #f5f8fa;
  --grey-200: #f0f3f5;
  --grey-300: #e0e4e9;
  --grey-400: #b0b9c1;
  --grey-500: #828a93;
  --grey-600: #6e7780;
  --grey-700: #5b636a;
  --grey-800: #464e56;
  --grey-900: #2c363f;
  --grey-950: #232a31;
  --grey-1000: #1d2228;
  --grey-1100: #000000;

  --blue-base: #0f69ff;
  --blue-100: #b8dbff;
  --blue-200: #52a8ff;
  --blue-300: #0f87ff;
  --blue-400: #0f69ff;
  --blue-500: #0059c9;
  --blue-600: #004894;
  --blue-700: #073560;
  --blue-800: #15212f;

  --purple-base: #9e62ff;
  --purple-100: #e6d7ff;
  --purple-200: #c3a8ee;
  --purple-300: #9f79de;
  --purple-400: #9e62ff;
  --purple-500: #7c4acd;
  --purple-600: #4e298c;
  --purple-700: #37196b;
  --purple-800: #150139;

  --green-base: #04c3c5;
  --green-100: #d2feff;
  --green-200: #88e7e8;
  --green-300: #3fd0d2;
  --green-400: #04c3c5;
  --green-500: #039599;
  --green-600: #01676d;
  --green-700: #14545b;
  --green-800: #003940;

  --red-base: #ff6f61;
  --red-100: #ffe6e4;
  --red-200: #ffafa7;
  --red-300: #ff8784;
  --red-400: #ff6f61;
  --red-500: #f35c62;
  --red-600: #d73666;
  --red-700: #c62368;
  --red-800: #771c43;

  --new_primary1: var(--blue-300);
  --new_primary2: rgba(15, 135, 255, 0.1);
  --new_primary3: #fff202;
  --new_primary4: #fff202;
  --new_primary5: #fff202;

  --new_doc1: #9483ff;
  --new_doc1bg: rgba(148, 131, 255, 0.1);
  --new_doc2: #f4349e;
  --new_doc2bg: rgba(244, 52, 158, 0.1);
  --new_doc3: #25cff4;
  --new_doc3bg: rgba(37, 207, 244, 0.1);
  --new_doc4: #44e871;
  --new_doc4bg: rgba(68, 232, 113, 0.1);
  --new_doc5: #f1ce52;
  --new_doc5bg: rgba(241, 206, 82, 0.1);
  --new_doc6: #f96855;
  --new_doc6bg: rgba(249, 104, 85, 0.1);
  --new_doc7: #65dec1;
  --new_doc7bg: rgba(101, 222, 193, 0.1);
  --new_doc8: #7a8fc2;
  --new_doc8bg: rgba(122, 143, 194, 0.1);
}

:root,
.light {
  --new_bg_canvas: var(--grey-000);
  --new_bg1: var(--grey-100);
  --new_bg2: var(--grey-200);
  --new_bg3: var(--grey-300);
  --new_line: var(--grey-400);
  --new_disabled: rgba(0, 0, 0, 0.15);

  --new_text0: var(--grey-1100);
  --new_text1: var(--grey-1000);
  --new_text2: var(--grey-800);
  --new_text3: var(--grey-500);

  --new_hover1: rgba(0, 0, 0, 0.15);
  --new_pressed1: rgba(0, 0, 0, 0.3);
  --new_selected1: rgba(0, 0, 0, 0.05);

  --new_fail1: #d65746;
  --new_fail2: rgba(214, 87, 70, 0.1);
  --new_success1: #08a84a;
  --new_success2: rgba(8, 168, 74, 0.1);
}

.dark {
  --new_bg_canvas: var(--grey-1000);
  --new_bg1: var(--grey-1100);
  --new_bg2: var(--grey-950);
  --new_bg3: var(--grey-900);
  --new_line: var(--grey-700);
  --new_disabled: rgba(255, 255, 255, 0.15);

  --new_text0: var(--grey-000);
  --new_text1: var(--grey-100);
  --new_text2: var(--grey-400);
  --new_text3: var(--grey-500);

  --new_hover1: rgba(255, 255, 255, 0.15);
  --new_pressed1: rgba(255, 255, 255, 0.3);
  --new_selected1: rgba(255, 255, 255, 0.05);

  --new_fail1: #f96855;
  --new_fail2: rgba(249, 104, 85, 0.1);
  --new_success1: #44e871;
  --new_success2: rgba(68, 232, 113, 0.1);
}

$new_primary1: var(--new_primary1);
$new_primary2: var(--new_primary2);
$new_primary3: var(--new_primary3);
$new_primary4: var(--new_primary4);
$new_primary5: var(--new_primary5);

$new_bg_canvas: var(--new_bg_canvas);
$new_bg1: var(--new_bg1);
$new_bg2: var(--new_bg2);
$new_bg3: var(--new_bg3);
$new_disabled: var(--new_disabled);
$new_line: var(--new_line);

$new_text1: var(--new_text1);
$new_text2: var(--new_text2);
$new_text3: var(--new_text3);

$new_hover1: var(--new_hover1);
$new_pressed1: var(--new_pressed1);
$new_selected1: var(--new_selected1);

$new_fail1: var(--new_fail1);
$new_fail2: var(--new_fail2);
$new_success1: var(--new_success1);
$new_success2: var(--new_success2);

$grey-base: var(--grey-base);
$grey-000: var(--grey-000);
$grey-100: var(--grey-100);
$grey-200: var(--grey-200);
$grey-300: var(--grey-300);
$grey-400: var(--grey-400);
$grey-500: var(--grey-500);
$grey-600: var(--grey-600);
$grey-700: var(--grey-700);
$grey-800: var(--grey-800);
$grey-900: var(--grey-900);
$grey-1000: var(--grey-1000);
$grey-1100: var(--grey-1100);

$blue-base: var(--blue-base);
$blue-100: var(--blue-100);
$blue-200: var(--blue-200);
$blue-300: var(--blue-300);
$blue-400: var(--blue-400);
$blue-500: var(--blue-500);
$blue-600: var(--blue-600);
$blue-700: var(--blue-700);
$blue-800: var(--blue-800);

$purple-base: var(--purple-base);
$purple-100: var(--purple-100);
$purple-200: var(--purple-200);
$purple-300: var(--purple-300);
$purple-400: var(--purple-400);
$purple-500: var(--purple-500);
$purple-600: var(--purple-600);
$purple-700: var(--purple-700);
$purple-800: var(--purple-800);

$green-base: var(--green-base);
$green-100: var(--green-100);
$green-200: var(--green-200);
$green-300: var(--green-300);
$green-400: var(--green-400);
$green-500: var(--green-500);
$green-600: var(--green-600);
$green-700: var(--green-700);
$green-800: var(--green-800);

$red-base: var(--red-base);
$red-100: var(--red-100);
$red-200: var(--red-200);
$red-300: var(--red-300);
$red-400: var(--red-400);
$red-500: var(--red-500);
$red-600: var(--red-600);
$red-700: var(--red-700);
$red-800: var(--red-800);
