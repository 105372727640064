@font-face {
  font-family: 'Agrandir';
  src: url('../../../assets/fonts/agrandir.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Agrandir Text';
  src: url('../../../assets/fonts/agrandir-text.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
}

@mixin agrandir {
  font-family: 'Agrandir', sans-serif;
  line-height: 1.25em;
}

@mixin agrandir-text {
  font-family: 'Agrandir Text', sans-serif;
  line-height: 1.5em;
}
