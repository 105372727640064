@use "sass:map";
$breakpoints: (
  sm: 768px,
  md: 1040px,
  lg: 1440px,
);

@mixin sm {
  @media (min-width: #{map.get($breakpoints, sm)}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{map.get($breakpoints, md)}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{map.get($breakpoints, lg)}) {
    @content;
  }
}

@mixin truncate {
  display: inline-block;
  padding-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate {
  @include truncate;
}

.mt_0 {
  margin-top: 0;
}

.mb_0 {
  margin-bottom: 0;
}

.mt_16 {
  margin-top: 16px;
}

.mb_16 {
  margin-bottom: 16px;
}

.mt_24 {
  margin-top: 24px;
}

.mb_24 {
  margin-bottom: 24px;
}

.flex_shrink_0 {
  flex-shrink: 0;
}

.flex_grow_0 {
  flex-grow: 0;
}

@mixn sm {
}
